import React, { useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import getUsers from "../../services/GET/getUsers";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import MainContext from "../../context/MainContext";
// import { sortByDates } from "../../utils/helperFunctions";
import { FORMAT_DATE_USER } from "../../utils/constants";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCreationType,
  selectDateFrom,
  selectDateTo,
  selectFilters,
  selectPage,
  selectRows,
  selectSize,
  selectSortBy,
  selectTotalRows,
  setPage,
  setRows,
  setSize,
  setSortBy,
  setTotalRows,
} from "./tableSlice";

const columns = [
  { field: "dateCreated", headerName: "Създадена", width: 155, align: "left" },
  { field: "name", headerName: "Име", width: 270, align: "left" },
  { field: "email", headerName: "Email", width: 230, align: "left" },
  { field: "risk", headerName: "Риск", width: 110, align: "left" },
  {
    field: "companyName",
    headerName: "Организация",
    width: 290,
    align: "left",
  },
  { field: "agentName", headerName: "Агент", width: 110, align: "left" },
  {
    field: "creationType",
    headerName: "KYC",
    width: 100,
    align: "left",
    valueGetter: (data) => {
      const { field, row } = data;
      return row[field] === "ONLINE" ? "Онлайн" : "Офлайн";
    },
  },
];

const TableUsers = ({ status }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const context = useContext(MainContext);
  const hash = context.user.hash;
  const fromDate = useSelector(selectDateFrom);
  const toDate = useSelector(selectDateTo);
  const page = useSelector(selectPage);
  const size = useSelector(selectSize);
  const totalRows = useSelector(selectTotalRows);
  const rows = useSelector(selectRows);
  const filters = useSelector(selectFilters);
  const isModerator = context.user.roles.includes("moderator");
  const sortBy = useSelector(selectSortBy);
  const creationType = useSelector(selectCreationType);

  useEffect(() => {
    dispatch(setRows([]));
    setLoading(true);
    getUsers(
      status,
      hash,
      page,
      size,
      filters,
      fromDate,
      toDate,
      sortBy,
      creationType
    ).then((resp) => {
      if (resp) {
        let sortedByDates = resp.respRows
        // sortByDates();

        const usersWithFormattedDate = sortedByDates.map((user) => {
          const formatted = FORMAT_DATE_USER(user.dateCreated);
          user["dateCreated"] = formatted;
          return user;
        });

        dispatch(setTotalRows(resp.totalRows));
        dispatch(setRows(usersWithFormattedDate));
        setLoading(false);
      }
    });

    // eslint-disable-next-line
  }, [filters, fromDate, toDate, page, size, sortBy, creationType]);

  const handleChangeRowsPerPage = (newSize) => {
    dispatch(setPage(0));
    dispatch(setSize(newSize));
  };

  const handlePageChange = (newPage) => {
    dispatch(setPage(newPage));
  };

  const handleClick = () => (event) => {
    const token = event.row.identityToken;
    history.push({
      pathname: `/details/${token}`,
    });
  };

  const handleSorting = (model) => {
    if (JSON.stringify(sortBy) !== JSON.stringify(model)) {
      dispatch(setRows([]));
      dispatch(setSortBy(model));
    }
  };

  return (
    <div>
      <DataGrid
        autoHeight
        components={{
          NoRowsOverlay: () => (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 240,
              }}
            >
              Няма записи
            </div>
          ),
        }}
        componentsProps={{
          pagination: {
            labelRowsPerPage: "Редове на страница",
          },
        }}
        // onRowOver={{ cursor: "pointer" }}
        labelRowsPerPage=" page"
        rowsPerPageOptions={[10, 25, 50]}
        onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
        onCellClick={handleClick()}
        rows={rows}
        page={page}
        rowCount={totalRows}
        onPageChange={handlePageChange}
        pageSize={size}
        disableSelectionOnClick
        disableColumnFilter={true}
        disableColumnMenu={true}
        paginationMode="server"
        loading={loading}
        columns={
          isModerator
            ? columns.map((column) => ({ ...column, sortable: true }))
            : columns
                .slice(0, 5)
                .map((column) => ({ ...column, sortable: true }))
        }
        onSortModelChange={handleSorting}
      />
    </div>
  );
};

export default TableUsers;
