import axios from "axios/index";
import { FORMAT_DATE_FILTER, GET_USERS } from "../../utils/constants";
import { translateRisk } from "../../utils/helperFunctions";

const getUsers = async (status, hash, page, size, filters, fromDate, toDate, sortBy, creationType) => {

  const q = new URLSearchParams();
  console.log(sortBy)
  q.append("page", page);
  q.append("size", size);
  q.append("status", status);

  if (sortBy.length && sortBy[0].field === "dateCreated") {
    q.append("sortType", sortBy[0].sort)
    q.append("sort", sortBy[0].field)
  } 
  // else if (sortBy.length && sortBy[0].field === "risk") {
  //   q.append("sortType", sortBy[0].sort)
  //   q.append("sort", sortBy[0].field)
  // }

  if (fromDate && fromDate.length) q.append("start", FORMAT_DATE_FILTER(fromDate))
  if (fromDate && fromDate.length && toDate && toDate.length) q.append("end", FORMAT_DATE_FILTER(toDate))

  if (filters.nationality && filters.nationality.length) q.append("nationality", filters.nationality)
  if (filters.risk && filters.risk.length) q.append("risk", filters.risk)
  if (filters.userType && filters.userType.length) q.append("userType", filters.userType)
  if (filters.sex && filters.sex.length) q.append("sex", filters.sex)
  if (filters.search && filters.search.length) q.append("search", filters.search)
  if (creationType && creationType.length) q.append("creationType", creationType)


  return await axios
    .get(
      GET_USERS(q.toString()),
      {
        headers: {
          Accept: "application/json",
          "Accept-language": "bg",
          "Authorization": hash,
        },
      }
    )
    .then((resp) => {
      const respRows = translateRisk(resp.data.data)
      const totalRows = resp.data.elements;
      return { respRows, totalRows }
    })
    .catch((err) => {
      console.error(err);
    });
};

export default getUsers;
