import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import UserInfo from "../components/userDetails/UserInfo";
import UserDataForm from "../components/userDetails/UserDataForm";
import IdCard from "../components/userDetails/IdCard";
import MainContext from "../context/MainContext";
import getUserByToken from "../services/GET/getUserByToken";
import Box from "@material-ui/core/Box";
import Loading from "../components/basic/LoadingTable";
import { Button, DialogTitle, Typography } from "@material-ui/core";
import CommentsBox from "../components/userDetails/CommentsBox";
import UserDetailsForm from "../components/userDetails/userDetailsForm";
import setUserStatus from "../services/PUT/setUserStatus";
import UserHistory from "../components/userDetails/UserHistory";
import IdCardModal from "../components/userDetails/IdCardModal";
import VideoModal from "../components/userDetails/VideoModal";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentUser,
  setCurrentUser,
} from "../components/userDetails/userDetailsSlice";
import { Dialog, DialogActions } from "@material-ui/core";
import { USER_STATUS, USER_STATUS_ENUMS } from "../utils/constants";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";
import deleteUser from "../services/DELETE/deleteUser";

const useStyles = makeStyles((theme) => ({
  optionsBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    width: "60%",
    float: "right",
    marginTop: theme.spacing(3),
  },
  deleteBtn: {
    background: theme.status.danger,
    "&:hover": {
      background: theme.status.hoverDanger,
    },
  },
  warningBtn: {
    background: theme.status.warning,
    "&:hover": {
      background: theme.status.hoverWarning,
    },
  },
  completeBtn: {
    background: theme.status.success,
    "&:hover": {
      background: theme.status.hoverSuccess,
    },
  },
  dialogActions: {
    minWidth: 300,
    display: "flex",
    justifyContent: "space-around",
  },
  backButton: {
    position: "absolute",
    top: 14,
    left: 300,

    color: theme.palette.common.white,
    [theme.breakpoints.down(1400)]: {
      left: 300,
    },
  },
}));

const UserDetails = (props) => {
  const classes = useStyles();
  const context = useContext(MainContext);
  const path = props.match.params.id;
  const hash = context.user.hash;
  const selectedUser = useSelector(selectCurrentUser);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [status, setStatus] = useState("");
  const history = useHistory();

  useEffect(() => {
    getUser();
    //eslint-disable-next-line
  }, [path]);

  const getUser = () => {
    getUserByToken(path, hash).then((resp) => {
      dispatch(setCurrentUser(resp));
      setLoading(false);
    });
  };

  const handleCompany = () => {
    context.openCompanyModal(true, selectedUser.companyIdentityToken);
  };

  const handleStatus = (stat) => () => {
    setStatus(stat);
    setDialogOpen(true);
  };

  const handleCLoseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirmStatus = async () => {
    await setUserStatus(status, hash, path);
    getUser();
    handleCLoseDialog();
    setStatus("");
  };

  const handleDelete = async () => {
    const resp = await deleteUser(hash, path);
    if (resp.status === 200) {
      history.goBack();
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Button
        onClick={() => history.goBack()}
        startIcon={<BackIcon />}
        className={classes.backButton}
      >
        Назад
      </Button>
      <Box mt={4} boxShadow={3}>
        <UserInfo hash={hash} token={path} />
        <CommentsBox token={path} hash={hash} />
        <UserHistory userId={path} hash={hash} />
        <Grid container spacing={1} justifyContent="space-evenly">
          <Grid item xs={4}>
            <UserDataForm token={path} hash={hash} getUser={getUser} />
          </Grid>
          <Grid item xs={6}>
            <IdCard
              idFrontImages={selectedUser.frontImages}
              idBackImages={selectedUser.backImages}
              documentPicture={selectedUser.documentPicture}
              smilingFaceImages={selectedUser.smilingFaceImages}
              frowningFaceImages={selectedUser.frowningFaceImages}
              video={selectedUser.video}
              videoFiles={selectedUser.videoFiles}
            />
            <div className={classes.optionsBox}>
              {selectedUser.companyName && (
                <>
                  <Typography style={{ marginRight: 20 }} variant="h5">
                    Организация: &ldquo;{selectedUser.companyName}&rdquo;
                  </Typography>

                  <Button
                    disabled={!selectedUser.companyIdentityToken}
                    onClick={handleCompany}
                    variant="contained"
                    color="primary"
                  >
                    Отвори
                  </Button>
                </>
              )}
            </div>
            <div className={classes.actions}>
              {selectedUser.status === USER_STATUS_ENUMS.PENDING && (
                <>
                  <Button
                    onClick={handleStatus("FAILED")}
                    className={classes.warningBtn}
                    variant="contained"
                    color="default"
                  >
                    Отхвърли
                  </Button>

                  <Button
                    onClick={handleStatus("SUCCESS")}
                    className={classes.completeBtn}
                    variant="contained"
                    color="primary"
                  >
                    Потвърди
                  </Button>
                </>
              )}

              {selectedUser.status === USER_STATUS_ENUMS.SUCCESS && (
                <>
                  <Button
                    onClick={handleStatus("FAILED")}
                    className={classes.warningBtn}
                    variant="contained"
                    color="default"
                  >
                    Отхвърли
                  </Button>
                </>
              )}

              {selectedUser.status === USER_STATUS_ENUMS.FAILED && (
                <>
                  <Button
                    onClick={handleStatus("SUCCESS")}
                    className={classes.completeBtn}
                    variant="contained"
                    color="primary"
                  >
                    Потвърди
                  </Button>{" "}
                  <Button
                    onClick={handleDelete}
                    className={classes.deleteBtn}
                    variant="contained"
                    color="secondary"
                  >
                    Изтрий
                  </Button>
                </>
              )}
              {selectedUser.status === USER_STATUS_ENUMS.STARTED && (
                <>
                  <Button
                    onClick={handleDelete}
                    className={classes.deleteBtn}
                    variant="contained"
                    color="secondary"
                  >
                    Изтрий
                  </Button>

                  <Button
                    onClick={handleStatus("FAILED")}
                    className={classes.warningBtn}
                    variant="contained"
                    color="default"
                  >
                    Отхвърли
                  </Button>

                  <Button
                    onClick={handleStatus("SUCCESS")}
                    className={classes.completeBtn}
                    variant="contained"
                    color="primary"
                  >
                    Потвърди
                  </Button>
                </>
              )}
            </div>
          </Grid>
        </Grid>

        <UserDetailsForm hash={hash} token={path} />
      </Box>
      <IdCardModal getUser={getUser} />
      <VideoModal />

      <Dialog onClose={handleCLoseDialog} open={dialogOpen}>
        <DialogTitle>
          <Typography variant="body1" align="center">
            {" "}
            Потвърди статус <b>{USER_STATUS[status]}</b>
          </Typography>
        </DialogTitle>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCLoseDialog}
          >
            Отказ
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirmStatus}
          >
            ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserDetails;
