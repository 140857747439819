export function sortByDates(arr) {
  const sorted = arr.sort((a, b) => {
    return new Date(b.dateCreated) - new Date(a.dateCreated);
  });

  return sorted;
}

// export function filterByDate(arr, fromDate, toDate) {
//   const filtered = arr.filter(
//     (item) =>
//       new Date(item.dateCreated).getTime() >= fromDate.getTime() &&
//       new Date(item.dateCreated).getTime() <= toDate.getTime()
//   );

//   return filtered;
// }

export function translateRisk(arr) {

  const translated = arr.reduce((acc, cVal) => {
    const currentRisk = cVal.risk

    switch (currentRisk) {
      case "HIGH":
        acc.push({ ...cVal, risk: "Висок" })
        break;
      case "MEDIUM":
        acc.push({ ...cVal, risk: "Среден" })
        break;
      case "LOW":
        acc.push({ ...cVal, risk: "Нисък" })
        break;
      default:
        acc.push(cVal)
        break;
    }

    return acc;
  }, []);

  return translated;
}

export function testImage(url, cb, timeout) {
  if (url.length) {
    timeout = timeout || 5000;
    var timedOut = false, timer;
    var img = new Image();
    img.onerror = img.onabort = function () {
      if (!timedOut) {
        clearTimeout(timer);
        cb(false)
      }
    };
    img.onload = function () {
      if (!timedOut) {
        clearTimeout(timer);
        cb(true)
      }
    };
    img.src = url;
    timer = setTimeout(function () {
      timedOut = true;
      cb(false)
    }, timeout);
  }


}

export function getBase64(file, cb) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
}