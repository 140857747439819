import { format } from "date-fns";

const { REACT_APP_BACKEND_URL } = process.env;
export const API_BASE_URL = `${REACT_APP_BACKEND_URL}`;

export const DELETE_ID_CARD_IMAGE = API_BASE_URL + "/users/image";
export const UPDATE_ID_CARD_IMAGE = API_BASE_URL + "/users/image";
export const GET_AGENTS = API_BASE_URL + "/agent/all";
export const CREATE_AGENT = API_BASE_URL + "/agent";
export const UPDATE_AGENT = API_BASE_URL + "/agent";
export const LOGIN = API_BASE_URL + "/login";
export const MODERATORS_NOT_ATTACHED = (agentId) =>
  `${API_BASE_URL}/agent/moderators/all?agentId=${agentId}`;
export const DELETE_AGENT = (agentId) =>
  `${API_BASE_URL}/agent/?agentId=${agentId}`;
export const GET_COMPANY_OWNERS = (companyToken) =>
  `${API_BASE_URL}/company/pep/owners?identityToken=${companyToken}`.replace("moderator/", "");
export const DOWNLOAD_OWNER_PEP = (companyToken, index) =>
  `${API_BASE_URL}/forms/download/${companyToken}/PEP2/?index=${index}`.replace("moderator/", "");
export const UPLOAD_LOGO = (uiId) =>
  `${API_BASE_URL}/agent/ui/logo/upload/${uiId}`;
export const UPLOAD_PDF = (uiConsentId) =>
  `${API_BASE_URL}/agent/consent/upload/${uiConsentId}`;
export const UPLOAD_AGENT_CERTIFICATE = (agentId) =>
  `${API_BASE_URL}/agent/upload/certificate/?agentId=${agentId}`;
export const GET_AGENT_UI = (agentId) => `${API_BASE_URL}/agent/ui/${agentId}`;
export const UPDATE_AGENT_UI = (agentId) =>
  `${API_BASE_URL}/agent/ui/${agentId}`;
export const GET_COMMENTS = (userToken) => `/comments/${userToken}`;
export const ADD_COMMENT = (userToken) =>
  `${API_BASE_URL}/comments/${userToken}`;
export const GET_COMPANIES = (Qstring) =>
  `${API_BASE_URL}/companies?${Qstring}`;
export const GET_COMPANY_BY_TOKEN = (companyToken) =>
  `${API_BASE_URL}/companies/${companyToken}`;
export const GET_USER_BY_TOKEN = (userToken) =>
  `${API_BASE_URL}/users/${userToken}`;
export const GET_USERS = (Qstring) => `${API_BASE_URL}/users?${Qstring}`;
export const SET_USER_DATA = (userToken) =>
  `${API_BASE_URL}/users/${userToken}`;
export const SET_USER_STATUS = (userToken, userStatus) =>
  `${API_BASE_URL}/users/status/${userToken}?status=${userStatus}`;
export const DELETE_CONSENT = (consentId) =>
  `/agent/consent/delete/${consentId}`;
export const SET_RISK = (token, risk) =>
  `${API_BASE_URL}/users/risk/${token}?risk=${risk}`;
export const FORMS = (identifyToken, type) =>
  `${API_BASE_URL}/forms/download/${identifyToken}/${type}/`.replace(
    "/moderator",
    ""
  );
export const DELETE_USER = (identityToken) =>
  `${API_BASE_URL}/identification/delete/${identityToken}`;
export const API_REGIX = (userToken) => API_BASE_URL + `/regix/${userToken}`;
export const ADD_IDENTIFICATION = API_BASE_URL + "/idenitification/offline";
export const UPLOAD_IMAGE = API_BASE_URL + "/idenitification/image";
export const REGIX_CHECK = (egn) => API_BASE_URL + "/activity/check?uic=" + egn;

export const ID_CARD_MODAL_TITLES = {
  frontImages: "Лична карта лице",
  backImages: "Лична карта гръб",
  frowningFaceImages: "Неутрално изражение",
  smilingFaceImages: "Усмихнато изражение",
  MoIImage: "МВР снимка",
};

export const DATE_FORMAT = "dd/MM/yyyy HH:mm:ss";

export const SCREENS = [
  {
    id: 1,
    title: "Идентификация",
    key: "homeDetails",
  },
  {
    id: 10,
    title: "Идентификация 1 потребител",
    key: "instructionSingleUser",
  },
  {
    id: 2,
    title: "Идентификация много потребители",
    key: "instructionMultiUsers",
  },
  {
    id: 3,
    title: "Лична карта лице",
    key: "instructionIdFace",
  },
  {
    id: 30,
    title: "Лична карта лице (Лейбъл)",
    key: "lblScanIdFace",
  },
  {
    id: 4,
    title: "Лична карта гръб",
    key: "instructionIdBack",
  },
  {
    id: 40,
    title: "Лична карта гръб (Лейбъл)",
    key: "lblScanIdBack",
  },
  {
    id: 5,
    title: "Успешна идентификация",
    key: "instructionIdentified",
  },
  {
    id: 6,
    title: "Допълнителни детайли",
    key: "instructionEnterOtherDetails",
  },
  {
    id: 7,
    title: "Политически Личности",
    key: "instructionPep",
  },
  {
    id: 8,
    title: "Политически Личности (Лейбъл)",
    key: "lblPep",
  },
  {
    id: 9,
    title: "Селфи видео",
    key: "instructionSelfieVideo",
  },
  {
    id: 90,
    title: "Селфи видео (Лейбъл)",
    key: "lblSelfie",
  },
  {
    id: 91,
    title: "Селфи видео (Потвърждение)",
    key: "lblSelfieVideoCheckbox",
  },
  {
    id: 105,
    title: "Език",
    key: "lng",
  },
  {
    id: 11,
    title: "Политически Личности",
    key: "instructionPep",
  },
  {
    id: 12,
    title: "Потвърждение",
    key: "instructionFinal",
  },
];

export const CONSENT_TEMPLATE = {
  agent: {
    agentHash: null,
    dateCreated: null,
    hook: null,
    id: 0,
    name: null,
    sendEmails: true,
  },
  dateCreated: new Date(),
  detailsUrl: null,
  id: null,
  key: null,
  label: null,
  lng: "bg",
  required: true,
};

export const USER_STATUS = {
  FAILED: "изтрий",
  SUCCESS: "потвърди",
  PENDING: "отхвърли",
};

export const FORMAT_DATE = (date) => format(new Date(date), "dd.MM.yyyy")

export const FORMAT_DATE_USER = (date) => format(new Date(date), "dd.MM.yyyy HH:mm")

export const FORMAT_DATE_FILTER = (date) => format(new Date(date), "yyyy-MM-dd")

export const FILTERS_TO_IGNORE = ["sex", "risk", "nationality", "userType"];

export const BENEFICIAL_OWNERS_TYPE = "BENEFICIAL_OWNERS2";
export const PEP_DECLARATION_SIGNATURE_FORM = "PEP_DECLARATION_SIGNATURE_FORM";

export const USER_STATUS_ENUMS = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  STARTED: "STARTED",
};

export const IMAGE_TYPE = {
  FRONT: "FRONT",
  BACK: "BACK",
};
